import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";



export default function Whitley() {
  return (
    <Layout pageTitle="Whitley Bay">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Whitley Bay - USI Projects</title>
        <meta name="description" content="USI was appointed to help communicate the vision of the regeneration of the Metro Station through concept designs through to architectural visualisations."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
      
        <section class="text-gray-700 body-font heading-container-whitley">
        <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Whitley Bay</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                  Nexus</p>
              </div>
          </section>
        </div>
      </section>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 class="text-6xl font-heading text-primaryteal">Overview</h3>
                <p
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl  ">Based in
                  the North East coast of England is Whitley Bay Metro Station. Our client Nexus, the main transport
                  provider for Tyne and Wear, seeks to 'Make public transport great for our environment, economy and
                  communities'. In this project Nexus wanted to celebrate a cultural and heritage community space
                  through the redevelopment of the station. USI was appointed to help communicate the vision of the
                  regeneration of the Metro Station through concept designs through to architectural visualisations,
                  with the supporting illustrations being part of a much larger Heritage Lottery Fund application.</p>

              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase ">Fields of
                  Practice</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">Heritage<br/>
                  Visual Strategy</p>
                <p class="text-2xl font-bold uppercase  mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">Stakeholder Liaison<br/>
                  Concept Sketches<br/>Visual Imagery</p>
                <p class="text-2xl font-bold uppercase mt-8">Location</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">Whitley Bay, England
                </p>
              </div>
            </div>
          </section>
        </div>
      </section>



      <div class="flex flex-wrap md:mb-0 md:mt-16">
        <div class="h-auto w-full md:w-1/2 md:mb-0">
          <img class="w-full h-full pr-2" src="https://ik.imagekit.io/usi/project-pages/whitley-left_Enf350NLtzov.png"
            alt="" width="720px"/></div>
        <div class="h-auto w-full md:w-1/2 md:mb-0">
          <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/whitley-right_EE9UiopWLO.png" alt=""
            width="720px"/></div>
      </div>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="w-full md:px-40 xl:w-1/2 py-4 xl:px-32 mb-4 md:mb-0">


                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="text-xl md:text-2xl  md:pt-9 p-6 md:pl-0 md:pr-0 md:pb-0 md:mb-16 ">
                  Protecting the integrity and authority of the Grade 2 listed building was of key importance and values
                  such as preserving heritage, engaging the community, restoring and regenerating architectural elements
                  lost to time and embracing nature helped inform our design approach. From the initial briefing, USI
                  created a series of sketches that were a direct translation of numerous ideas shared amongst
                  stakeholders, Nexus and USI. These were then developed digitally to serve as a more tangible vision
                  for a much greater strategy.
                </p>

                <div class="md:pt-9 p-6 md:pl-0 md:pr-0 md:pb-0 md:mb-16">
                  <h2 data-scroll data-scroll-speed="1"
                    class="text-5xl leading-tight font-semibold font-heading text-primaryteal">USI developed 5 key
                    themes set out by Nexus</h2>
                  <ul data-scroll data-scroll-speed="1" class="mt-5 ml-8">
                    <li data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true"
                      class="text-xl md:text-2xl  ">&nbsp;Refurbishment of the Station Building</li>
                    <li data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true"
                      class="text-xl md:text-2xl  mt-4 ">&nbsp;Repurposing of derelict internal space
                    </li>
                    <li data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true"
                      class="text-xl md:text-2xl  mt-4 ">&nbsp;Reconfiguration and refurbishment of
                      external space, including planting and seating and events/exhibition items</li>
                    <li data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true"
                      class="text-xl md:text-2xl  mt-4 ">&nbsp;Reinstating the Victorian Greenhouse
                    </li>
                    <li data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true"
                      class="text-xl md:text-2xl  mt-4 ">&nbsp;Regeneration to the extensive garden
                      spaces</li>
                  </ul>
                </div>
              </div>
              <div data-scroll data-scroll-speed="3" data-scroll-class="scroll-class" data-scroll-repeat="true"
                class="w-full md:w-9/12 md:m-auto xl:w-1/2 py-4 sm:px-8 md:px-24 mb-4 md:mb-0 ">
                <img src="https://ik.imagekit.io/usi/project-pages/whitley-entrance_ce7cY7hAz.png" alt="foyle app"/>

              </div>
            </div>
          </section>
        </div>
      </section>






      <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/06_the-garden_1_cVotiEUtnU.png" alt=""></img>

      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-green-400">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../christmasshop" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
